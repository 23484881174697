<template>
    <form method="post" class="grid grid-cols-12 gap-6 mt-4" :class="{ 'disabled-form': loading }">
        <div v-if="loading" class="absolute z-100 left-0 top-0 w-full h-full flex items-center justify-center">
            <Preloader />
        </div>

        <div class="col-span-12 flex items-center">
            <input
                id="form-active"
                v-model="form.api_update_enabled"
                class="show-code form-check-switch flex-none mr-0"
                type="checkbox"
            />
            <label for="form-published" class="form-check-label">Синхронизировать данные от провайдера</label>
        </div>

        <div class="col-span-12">
            <label class="form-label">Описание</label>
            <CustomWyswig v-model="form.description" />
        </div>

        <div class="col-span-12 flex items-center justify-end mt-5">
            <router-link
                :to="{ name: 'cruise-timetable', params: { cruise_id: $route.params.cruise_id } }"
                class="btn btn-secondary w-24 mb-2 mr-2"
                >Отмена
            </router-link>
            <button type="submit" class="btn btn-primary mb-2 mr-2" @click.prevent="save(false)">Сохранить</button>
        </div>
    </form>
</template>
<script>
import { errorResponse, formPreparation } from '@/mixins/form/form-mixin';
import CustomWyswig from '@/components/base/custom-wyswig/Main';
import Preloader from '@/components/preloader/Main';

export default {
    name: 'Edit',
    components: { Preloader, CustomWyswig },
    mixins: [errorResponse, formPreparation],
    data() {
        return {
            loading: false,
            form: {
                description: null,
                api_update_enabled: false,
            },
        };
    },
    created() {
        this.fetchData();
    },
    methods: {
        async fetchData() {
            this.loading = true;
            this.axios
                .get(
                    `/cruises/${this.$route.params.cruise_id}/timetable/${this.$route.params.cruise_timetable_id}/edit`
                )
                .then(({ data }) => {
                    const fields = data.data;
                    this.form.description = fields.description;
                    this.form.api_update_enabled = fields.api_update_enabled;

                    this.loading = false;
                })
                .catch((ex) => {
                    console.log('cant fetch single data: ' + ex);
                    this.loading = false;
                    // this.$router.push('/error-page');
                });
        },
        save(flag) {
            this.loading = true;
            this.form.seo = this.seo;
            const formData = this.formPreparation();

            this.axios
                .post(
                    `/cruises/${this.$route.params.cruise_id}/timetable/${this.$route.params.cruise_timetable_id}`,
                    formData,
                    { params: { _method: 'patch' } }
                )
                .then((res) => {
                    if (flag) {
                        this.fetchData();
                    } else {
                        this.$router.push({
                            name: 'cruise-timetable',
                            params: { cruise_id: this.$route.params.cruise_id },
                        });
                    }

                    this.$notify('Данные сохранены');
                })
                .catch((ex) => {
                    this.errorResponse(ex);
                });
        },
    },
};
</script>
